var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.containsSlider ? "client-only" : "div",
    {
      tag: "component",
      attrs: { "data-is-first": _vm.isFirst, "data-is-last": _vm.isLast },
    },
    [
      _c(
        "Intersection",
        { attrs: { "on-reveal": _vm.onReveal } },
        [
          _c(
            "Intersection",
            {
              attrs: {
                "root-margin": "-33% 0px -66% 0px",
                "on-enter": _vm.onEnter,
                "use-reveal-attributes": false,
              },
            },
            [
              _c(
                "div",
                { attrs: { id: _vm.moduleId } },
                [
                  _c(
                    _vm.moduleName,
                    _vm._b(
                      { tag: "component" },
                      "component",
                      _vm.combinedProperties,
                      false
                    )
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }