var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "modules" },
    _vm._l(_vm.modules, function (mod, index) {
      return _c(
        "div",
        { key: `${mod.id}-${mod.__component}` },
        [
          _c("ModuleRenderer", {
            attrs: {
              mod: mod,
              "is-last": index === _vm.modules.length - 1,
              "is-first": index === 0,
              id: index === 1 ? "content" : "",
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }