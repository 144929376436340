<template>
  <component
    :is="containsSlider ? 'client-only' : 'div'"
    :data-is-first="isFirst"
    :data-is-last="isLast"
  >
    <Intersection :on-reveal="onReveal">
      <Intersection 
        root-margin="-33% 0px -66% 0px"
        :on-enter="onEnter"
        :use-reveal-attributes="false"
      >
        <div :id="moduleId">
          <component :is="moduleName" v-bind="combinedProperties"> </component>
        </div>
      </Intersection>
    </Intersection>
  </component>
</template>

<script>
import { mapGetters } from 'vuex'
import * as uiComponents from '@yoc/ui'
//import BaseModule from './../../ui/src/mixins/module-mixin';
import helpers from '../helpers'

export default {
  //mixins: [BaseModule],
  data() {
    return {
      revealed: false,
    }
  },
  props: {
    mod: {
      type: Object,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ...uiComponents,
  },
  methods: {
    navigate(href) {
      if (href.indexOf('http') >= 0) {
        window.open(href, '_blank')
      } else {
        this.$router.push(href)
      }
    },
    onReveal() {
      this.revealed = true;
    },
    onEnter() {  
      const event = new CustomEvent('activeMenuItem', { detail: this.moduleId });
      window.dispatchEvent(event);
    },
  },
  computed: {
    ...mapGetters({
      settingsByLocale: 'settings/settingsByLocale',
    }),
    settings() {
      const { locale } = this.$i18n
      const settings = this.settingsByLocale(locale)
      return settings
    },
    moduleName() {
      return helpers.getModuleName(this.mod.__component)
    },
    moduleId() {
      return `${this.moduleName}-${this.mod.id}`
    },
    baseModuleProps() {
      return {
        'on-click': this.navigate,
        id: null,
      }
    },
    containsSlider() {
      return !!(
        this.moduleName === 'ongoing-events' ||
        this.moduleName === 'events-slider' ||
        this.moduleName === 'agenda' ||
        this.moduleName === 'text-slider' ||
        this.moduleName === 'images-slider' ||
        this.moduleName === 'posts-slider' ||
        this.moduleName === 'contact-grid' && this.mod.type === 'slider'
      )
    },
    combinedProperties() {
      return {
        ...helpers.injectSettingsIntoModule(this.settings, this.moduleName),
        ...this.mod,
        ...this.baseModuleProps,
        ...{ revealed: this.revealed },
      }
    },
  },
}
</script>
