<template>
  <div class="modules">
    <div v-for="(mod, index) in modules" :key="`${mod.id}-${mod.__component}`">
      <ModuleRenderer
        :mod="mod"
        :is-last="index === modules.length - 1"
        :is-first="index === 0"
        v-bind:id="index === 1 ? 'content' : ''"
      ></ModuleRenderer>
    </div>
  </div>
</template>

<script>
import helpers from '@/helpers'
import ModuleRenderer from '@/components/module-renderer'
import { loadDynamicDataIntoModule } from '@/helpers/dynamic'

export default {
  components: {
    ModuleRenderer,
  },
  async asyncData({
    $axios,
    isDev,
    route,
    store,
    env,
    params,
    query,
    req,
    res,
    redirect,
    error,
    $strapi,
    i18n,
  }) {
    const { locale } = i18n
    let modules = []
    let seo
    const { entity, slug } = helpers.getSlug(params, locale)

    try {
      const page = await $strapi.find(entity, { slug, _locale: locale })

      if (page.length > 0) {
        const { content } = page[0]

        seo = page[0].seo
        modules = await loadDynamicDataIntoModule($strapi, content, locale, store)
      } else {
        error({
          statusCode: 404,
        })
      }
    } catch (err) {
      console.log(err)
      error({
        statusCode: 404,
      })
    }

    return {
      modules,
      seo,
    }
  },
  head() {
    return helpers.injectMeta(this)
  },
}
</script>
